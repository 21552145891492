import React, { Component } from 'react';

import './Content.scss';

import Loader from './Loader';
import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';
import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

class Content extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      imageVideoTextPosition: 'bottom',
      mobileScrollHintVisible: false
    }
    this.imageVideoTextMobilePosition = this.imageVideoTextMobilePosition.bind(this);
    this.hideScrollHint = this.hideScrollHint.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
    });
    this.imageVideoTextMobilePosition();
  }

  // Check first column module text position. Set this position for other grid modules in mobile view
  imageVideoTextMobilePosition() {
    if (this.props.data.cell_1 && (this.props.data.cell_1.module === 'imagetext' || this.props.data.cell_1.module === 'videotext')) {
      this.setState({
        imageVideoTextPosition: this.props.data.cell_1.text_pos,
      });
    }
  }

  // Hide mobile scroll hint on tap
  hideScrollHint() {
    const visibleHint = document.querySelector('.anchor.visible');
    if (visibleHint) {
      visibleHint.classList.remove('visible');
    }
  }

  render () {
    return (
      <div>
        <div
          className={'module content-grid-module ' + (this.state.data.is_header_module === 'no' ? '' : ' content-grid-module-master-header') + (this.state.data.masthead_legacy_padding === 1 ? ' content-grid-module-master-header-legacy-padding' : '') + (this.state.data.head_height === '100%' ? ' module-height-fold' : '') + (this.state.data.parallax_mode === 1 ? ' parallax' : '')}
          style={{
            paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
            paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
            backgroundColor:this.state.data.background === 'transparent' ? this.props.brand.main_background_color : this.state.data.background,
            backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
            backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%',
            minHeight: this.state.data.head_height !== '100%' && this.state.data.is_header_module === 'yes' ? this.state.data.head_height+'px' : ''
          }}
        >
          <VideoBackground src={this.state.data.bg_video} />

          {this.state.data.dim === 1 && (
            <div className="module-bg-dim"></div>
          )}

          <ShareLocation module={this.state.data.id} isMasthead={this.state.data.is_header_module} />

          {/* Mobile scroll hint */}
          {this.state.data.mobile_layout === 'horizontal' && this.state.data.layout !== 'one' && this.state.data.full_width !== 1 && (
          <div className="mobile-scroll-hint" onTouchStart={this.hideScrollHint}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 30">
              <g>
                <path d="M8,19c-0.3,0-0.5-0.1-0.7-0.3l-6-6c-0.4-0.4-0.4-1,0-1.4c0,0,0,0,0,0l6-6c0.4-0.4,1-0.4,1.4,0
                  c0.4,0.4,0.4,1,0,1.4l0,0L3.4,12l5.3,5.3c0.4,0.4,0.4,1,0,1.4c0,0,0,0,0,0C8.5,18.9,8.3,19,8,19z M16.7,18.7l6-6
                  c0.4-0.4,0.4-1,0-1.4c0,0,0,0,0,0l-6-6c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l5.3,5.3l-5.3,5.3c-0.4,0.4-0.4,1,0,1.4c0,0,0,0,0,0
                  C15.7,19.1,16.3,19.1,16.7,18.7C16.7,18.7,16.7,18.7,16.7,18.7z"/>
              </g>
            </svg>
          </div>
          )}

          <div className={'module_content mobile-layout-'+this.state.data.mobile_layout+' module_content--'+this.state.data.layout+' row column-padding-'+this.state.data.column_padding+' grid-'+this.state.data.grid+' full--'+this.state.data.full_width}>

            <div className="row" style={{
                ...(this.state.data.full_width != 1 ? {
                  marginLeft: `-${this.state.data.column_padding_px}px`,
                  marginRight: `-${this.state.data.column_padding_px}px`,
                } : {}),
                width: 'calc(100% + ' + this.state.data.column_padding_px * 2 +'px'
              }}>
              <div style={{
                  paddingLeft: this.state.data.column_padding_px+'px',
                  paddingRight: this.state.data.column_padding_px+'px',
                  background: this.state.data.cell_1 && this.state.data.cell_1_background ? this.state.data.cell_1_background : 'transparent'
                }}
                className="module_content_column col_1">
                {this.state.data.cell_1 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  layout={this.state.data.layout}
                  isMasthead={this.state.data.is_header_module === 'yes'}
                  data={this.state.data.cell_1}
                  brand={this.props.brand}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_1 && (
                <div className="empty"></div>
                )}
              </div>

              {this.state.data.layout !== 'one' && (
              <div style={{
                  paddingLeft: this.state.data.column_padding_px+'px',
                  paddingRight: this.state.data.column_padding_px+'px',
                  background: this.state.data.cell_2 && this.state.data.cell_2_background ? this.state.data.cell_2_background : 'transparent'
                }}
                className="module_content_column col_2">
                {this.state.data.cell_2 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  layout={this.state.data.layout}
                  brand={this.props.brand}
                  data={this.state.data.cell_2}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_2 && (
                <div className="empty"></div>
                )}
              </div>
              )}

              {this.state.data.layout !== 'one' && this.state.data.layout !== 'two' && (
              <div style={{
                  paddingLeft: this.state.data.column_padding_px+'px',
                  paddingRight: this.state.data.column_padding_px+'px',
                  background: this.state.data.cell_3 && this.state.data.cell_3_background ? this.state.data.cell_3_background : 'transparent'
                }}
                className="module_content_column col_3">
                {this.state.data.cell_3 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  layout={this.state.data.layout}
                  data={this.state.data.cell_3}
                  brand={this.props.brand}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_3 && (
                <div className="empty"></div>
                )}
              </div>
              )}

              {this.state.data.layout === 'four' && (
              <div style={{
                  paddingLeft: this.state.data.column_padding_px+'px',
                  paddingRight: this.state.data.column_padding_px+'px',
                  background: this.state.data.cell_4 && this.state.data.cell_4_background ? this.state.data.cell_4_background : 'transparent'
                }}
                className="module_content_column col_4">
                {this.state.data.cell_4 && (
                <Loader
                  fullWidth={this.state.data.full_width === 1 && this.state.data.layout === 'one'}
                  data={this.state.data.cell_4}
                  brand={this.props.brand}
                  audio={this.props.audio}
                  audioStatus={this.props.audioStatus}
                  audioProgress={this.props.audioProgress}
                  playAudio={this.props.playAudio}
                  pauseAudio={this.props.pauseAudio}
                  openModal={this.props.openModal}
                  zoomImages={this.state.data.gallery_mode}
                  imageVideoTextPosition={this.state.imageVideoTextPosition}
                />
                )}

                {!this.state.data.cell_4 && (
                <div className="empty"></div>
                )}
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
