import React, { Component } from 'react';
import './Grid.scss';

import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';
import Masonry from 'react-masonry-component';

import 'libs/react-photoswipe/lib/photoswipe.css';
import { PhotoSwipe } from 'libs/react-photoswipe/src';

import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

const masonryOptions = {
    transitionDuration: 50,
};

const imagesLoadedOptions = {
  background: '.my-bg-image-el'
}

class Grid extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      galleryIndex: 0,
      gallery: [],
      galleryOpen: false,
      resetHeight: false,
      zoomImages: false,
    }
    this.parentRef = React.createRef();
  }

  componentDidMount() {
    // Set gallery images
    let gallery = [];
    for(let i = 0; i < this.props.data.items.length; i++) {
      let image = '';
      if(this.props.data.items[i].type !== 'video') {
        image = {
          src: this.props.data.items[i].image_1200,
          w: this.props.data.items[i].width ? this.props.data.items[i].width : 1500,
          h: this.props.data.items[i].height ? this.props.data.items[i].height : 1500,
        }
      } else {
        image = {
          html: '<div class="videoSlide">' +
                  '<div class="videoSlideInner">' +
                    '<video autoplay playsinline muted loop controls><source src="' +
                    this.props.data.items[i].filename + '" type="video/mp4" />' +
                    '</video>' +
                  '</div>' +
                '</div>'
        }
      }

      this.props.data.items[i].attachmentClass = 'btn-download-float';
      this.props.data.items[i].x = 0;
      this.props.data.items[i].y = 0;



      gallery.push(image);
    }

    this.setState({
      data: this.props.data,
      items: this.props.data.items,
      zoomImages: this.props.data.gallery_mode === 1,
      gallery: gallery
    });

    // Direct open modal
    const urlParams = new URLSearchParams(window.location.search);

    if(urlParams.get('p') && urlParams.get('m')*1 === this.props.data.id) {
      this.props.data.items.map((item, index) => {
        if(item.id === urlParams.get('p')*1) {
          let self = this;

          setTimeout(function(){
            self.showModal(item,index);
          },1000);

          return;
        }
      });
    }

    setTimeout(() => {
      this.setState({
        resetHeight: true,
      });
    },500);
    /*
    setTimeout(() => {
      let containerStyle = window.getComputedStyle(this.parentRef.current);
      console.log(window.getComputedStyle(ReactDOM.findDOMNode(this.parentRef.current)).getPropertyValue("width"));
    },500)
    */

  }

  imageHasDownloads = (item) => {
    return item.attachments && item.attachments.length > 0;
  }

  showModal = (item,index) => {
    if(!this.imageHasDownloads(item)) return false;

    let newItem = item;
    newItem.image = item.filename;

    this.props.openModal(item,this.state.gallery,index);
  }

  imageHeight = (item) => {
    let widths = [];
    widths['0'] = [];
    widths['1'] = [];
    widths['1']['3'] = 245;
    widths['1']['4'] = 336.66;
    widths['1']['6'] = 520;
    widths['0']['3'] = widths['1']['3']+20;
    widths['0']['4'] = widths['1']['4']+20;
    widths['0']['6'] = widths['1']['6']+20;

    return item.height/(item.width/widths[this.state.data.column_padding][this.state.data.layout])+'px';
  }

  openPhotoSwipe = (index) => {
    if(!this.state.zoomImages) return false;
    this.setState({
      galleryOpen: true,
      galleryIndex: index,
    });
  };

  handleClose = () => {
    this.setState({
      galleryOpen: false
    });
  };

  _onMouseEnter(e, item) {
    if(item.hover_type === 'video') {
      let video = document.querySelector('#video_item_'+item.id+' .hover-hover video')
      video.currentTime = 0;
    }
    if(this.state.zoomImages && !this.imageHasDownloads(item)) return true;
    let items = this.state.items;
    let index = items.findIndex(image => image.id === item.id);
    items[index].attachmentClass = 'btn-download-float visible';
    this.setState({ items: items });
  }

  _onMouseMove(e, item) {
    if(this.state.zoomImages && !this.imageHasDownloads(item)) return true;
    let items = this.state.items;
    let index = items.findIndex(image => image.id === item.id);
    items[index].x = e.clientX + 5;
    items[index].y = e.clientY - 30;
    this.setState({ items: items });
  }

  _onMouseLeave(e, item) {
    if(this.state.zoomImages && !this.imageHasDownloads(item)) return true;
    let items = this.state.items;
    let index = items.findIndex(image => image.id === item.id);
    items[index].attachmentClass = 'btn-download-float';
    this.setState({ items: items });
  }

  render () {
    const { galleryIndex, galleryOpen, gallery } = this.state;

    return (
      <div id="parent" ref={this.parentRef}>
        <div
          className={'module' + (this.state.data.show_image_desc === 1 ? ' module--grid-titles' : '')}
          style={{
            paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
            paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
            backgroundColor:this.state.data.background === 'transparent' ? this.props.brand.main_background_color : this.state.data.background,
            backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
            backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
          }}
        >
          <VideoBackground src={this.state.data.bg_video} />
          {this.state.data.dim === 1 && (
            <div className="module-bg-dim"></div>
          )}
          <ShareLocation module={this.state.data.id} />
          <div className={'module-grid module-grid--'+this.state.data.layout+' row column-padding-'+this.state.data.column_padding+' full--'+this.state.data.full_width} >
            <div className="col-12"
              style={{
                marginTop: -this.state.data.column_padding_value + 'px',
                marginBottom: -this.state.data.column_padding_value + 'px'
              }}
            >
              {this.state.data.text && (
              <div className="module_grid__text">
                <div className="dynamic-content" style={{'width':this.state.data.text_width+'px', 'left':this.state.data.text_left+'px', 'top':this.state.data.text_top+'px'}}>
                  <div className="editor-content" dangerouslySetInnerHTML={{__html: this.state.data.text}}></div>
                </div>
              </div>
              )}

              {this.state.items && (
              <div style={{marginLeft: this.state.data.column_padding === 1 ? -this.state.data.column_padding_value+'px': '', marginRight: this.state.data.column_padding === 1 ? -this.state.data.column_padding_value+'px': ''}} className={'corebook-grid' + (this.state.data.column_padding !== 1 ? ' gutterless' : ' gutterless')}>
                  <Masonry
                      options={masonryOptions} // default {}
                      disableImagesLoaded={false} // default false
                      updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
                      imagesLoadedOptions={imagesLoadedOptions} // default {}
                  >
                    {this.state.items.map((item, index) =>
                    <div id={'video_item_' +item.id} key={item.id} className={item.hover ? 'brick with--hover' : 'brick'}>
                      <div
                        className="image__image"
                        style={{cursor: this.imageHasDownloads(item) ? 'pointer' : (this.state.zoomImages ? 'zoom-in' : 'default')}}
                        onClick={(e) => { e.preventDefault(); this.imageHasDownloads(item) ? this.showModal(item,index) : this.openPhotoSwipe(index) }}
                        onMouseEnter={(e) => { this._onMouseEnter(e,item) }}
                        onMouseMove={(e) => { this._onMouseMove(e,item) }}
                        onMouseLeave={(e) => { this._onMouseLeave(e,item) }}
                      >
                        <div className="grid-item-wrapper" style={{borderRadius: this.state.data.border_radius, margin: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px': ''}}>
                          <div className="hover-default">
                            {item.type === 'image' &&
                              <img
                                style={{height: this.state.resetHeight ? 'auto' : this.imageHeight(item)}}
                                src={this.state.data.full_width === 1 && this.state.data.layout === '6' ? item.image_1200 : item.filename}
                                alt=""
                              />
                            }
                            {item.type === 'video' &&
                              <video style={{height: this.state.resetHeight ? 'auto' : this.imageHeight(item)}} autoPlay loop muted playsInline>
                                  <source src={item.filename} type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                            }
                          </div>
                          {item.hover &&
                            <div className="hover-hover">
                              {item.hover_type === 'image' &&
                                <img src={this.state.data.full_width === 1 && this.state.data.layout === '6' ? item.hover_1200 : item.hover_1200} style={{height: this.state.resetHeight ? 'auto' : this.imageHeight(item)}} alt=""/>
                              }

                              {item.hover_type === 'video' &&
                                <video style={{height: this.state.resetHeight ? 'auto' : this.imageHeight(item)}} autoPlay loop muted playsInline>
                                  <source src={item.hover} type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                              }
                            </div>
                          }
                        </div>
                        <div className="title-wrap" style={{marginLeft: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px' : '', marginRight: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px' : '', marginBottom: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px' : ''}}>
                          <div
                            className="title-quill editor-content"

                                style={{display: !item.description ? 'none' : 'block',color: this.state.data.title_color === '#09163d' || this.state.data.title_color === '#16171a' ? this.state.brand.text_color : this.state.data.title_color}} dangerouslySetInnerHTML={{__html: item.description ? item.description : '&nbsp;'}}>
                          </div>
                        </div>

                        {this.imageHasDownloads(item) && (
                        <div className={item.attachmentClass} style={{left: item.x, top:item.y }} onClick={() => { this.showModal(item,index) }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                        </div>
                        )}
                      </div>
                    </div>
                    )}
                </Masonry>
              </div>
              )}
            </div>
          </div>
        </div>

        <PhotoSwipe
          isOpen={galleryOpen}
          items={gallery}
          options={{
            index: galleryIndex,
            captionEl: false,
            shareEl: false,
            closeOnScroll: true,
            zoomEl: false,
            history: false,
            mainClass: 'GridModule',
            barsSize: {top:0, bottom:'auto'}
          }}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default Grid;
