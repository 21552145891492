import React, { Component, useState, useRef } from 'react';

// Parse CSS as React camelCase
function parseCssString(cssString) {
  const cssObject = {};

  if (cssString) {
    // Split the string into individual styles
    const stylesArray = cssString.split(';');

    stylesArray.forEach(style => {
      if (style.trim() === '') return;

      // Split each style into property and value
      let [property, value] = style.split(':');

      if (property && value) {
        // Convert CSS property to camelCase
        const camelCaseProperty = property.trim().replace(/-([a-z])/g, (g) => g[1].toUpperCase());
        if (property === 'top' || property === 'left' || property === 'width' || property === 'height') {
          cssObject[camelCaseProperty] = value;
        } else {
          cssObject[camelCaseProperty] = value.trim();
        }
      }
    });
  }

  return cssObject;
}

// Calculate viewport compensation
function calculateViewport(viewport) {
  return 1 + (1 - viewport);
}

// Calculate mobile font sizes, compensate scale
function calculateFontSize(text, viewport) {
  if (window.innerWidth > 768) {
    return text;
  }

  // Get all font-size declarations
  const regex = /font-size:\s*(\d+)px/g;

  return text.replace(regex, (match, p1) => {
    const newSize = parseInt(p1, 10) / viewport;
    return `font-size:${newSize}px`;
  });

  return text;
}

// Recursive Tree Component
const TreeNode = ({ node, items, viewport, props }) => {
  let [x, setX] = useState(0);
  let [y, setY] = useState(0);
  let [attachmentClass, setAttachmentClass] = useState('btn-download-float');
  const hoverVideo = useRef(null);
  const thisNode = findImageByNodeId(items, node.id);

  function findImageByNodeId(items, targetNodeId) {
    return items.find(item => item.node_id === targetNodeId);
  }

  function imageHasDownloads(node) {
    return node.attachments && node.attachments.length > 0;
  }

  function showModal(item) {
    let newItem = item;
    newItem.image = item.image;

    props.openModal(item, [], 0);
  }

  function formatUrl(url) {
    if (!isNaN(url) || url.charAt(0) === '+') {
      return 'tel:'+url;
    }

    if (url.indexOf('mailto:') === -1 && url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('@') !== -1) {
      return 'mailto:'+url;
    }

    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1 && url.indexOf('mailto:') === -1 && url.indexOf('anchor:') === -1 && url.indexOf('page:') === -1) {
      return 'https://'+url;
    }

    return url;
  }

  function _onMouseEnter(e) {
    setAttachmentClass('btn-download-float visible');
    if (hoverVideo.current) {
      hoverVideo.current.currentTime = 0;
    }
  }

  function _onMouseMove(e) {
    const element = e.currentTarget; // The current hovered element
    const hoverDefault = element.querySelector('.hover-default');

    const parentOffset = hoverDefault.getBoundingClientRect();

    const relativeX = (e.clientX - parentOffset.left + (5 * 1.45)) / viewport;
    const relativeY = (e.clientY - parentOffset.top - (30 * viewport)) / viewport;

    setX(relativeX);
    setY(relativeY);

    setAttachmentClass('btn-download-float visible');
  }

  function _onMouseLeave(e) {
    setAttachmentClass('btn-download-float');
  }

  return (
    <div figma-id={node.id} figma-name={node.name} figma-type={node.type} style={parseCssString(node.css)}>
      {/* Image not fill */}
      {node.image && !node.imageFill && (
        <div style={{width: '100%', height: '100%'}} onMouseEnter={_onMouseEnter} onMouseMove={_onMouseMove} onMouseLeave={_onMouseLeave}>
          <div className={thisNode.hover ? 'image with--hover' : 'image'}>
            {!thisNode.url && (
              <div style={{cursor: imageHasDownloads(thisNode) ? 'pointer' : 'default', height: '100%'}} onClick={(e) => { imageHasDownloads(thisNode) ? showModal(thisNode) : e.preventDefault() }}>
                <div className="hover-default">
                  <img src={thisNode.image} alt="" />
                </div>

                {thisNode.hover &&
                  <div className="hover-hover">
                    {thisNode.hover_type === 'image' &&
                      <img src={thisNode.hover} alt="" />
                    }
                    {thisNode.hover_type === 'video' &&
                      <video ref={hoverVideo} autoPlay loop muted playsInline>
                        <source src={thisNode.hover} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    }
                  </div>
                }
              </div>
            )}

            {thisNode.url && (
              <div style={{height: '100%'}}>
                <a href={formatUrl(thisNode.url)} target={thisNode.target} rel="noopener noreferrer" style={{display: 'block', height: '100%'}}>
                  <div className="hover-default">
                    <div style={{cursor: 'pointer', height: '100%'}}>
                      <img src={thisNode.image} alt="" />
                    </div>
                  </div>

                  {thisNode.hover &&
                    <div className="hover-hover">
                      {thisNode.hover_type === 'image' &&
                        <img src={thisNode.hover} alt="" />
                      }
                      {thisNode.hover_type === 'video' &&
                        <video ref={hoverVideo} autoPlay loop muted playsInline>
                          <source src={thisNode.hover} type="video/mp4"/>
                          Your browser does not support the video tag.
                        </video>
                      }
                    </div>
                  }
                </a>
              </div>
            )}

            {imageHasDownloads(thisNode) && (
              <div className={attachmentClass} style={{left:x, top:y, transform: 'scale('+calculateViewport(viewport)+')'}} onClick={(e) => { e.preventDefault(); showModal(thisNode) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Image fill container */}
      {node.image && node.imageFill && (
        <div style={{width: '100%', height: '100%'}} onMouseEnter={_onMouseEnter} onMouseMove={_onMouseMove} onMouseLeave={_onMouseLeave}>
          <div className={thisNode.hover ? 'image with--hover' : 'image'}>
            {!thisNode.url && (
              <div style={{cursor: imageHasDownloads(thisNode) ? 'pointer' : 'default', height: '100%'}} onClick={(e) => { imageHasDownloads(thisNode) ? showModal(thisNode) : e.preventDefault() }}>
                <div className="hover-default" style={{background: 'url('+thisNode.image+') no-repeat', backgroundSize: 'contain'}}>
                  &nbsp;
                </div>

                {thisNode.hover &&
                  <div className="hover-hover">
                    {thisNode.hover_type === 'image' &&
                      <img src={thisNode.hover} alt="" />
                    }
                    {thisNode.hover_type === 'video' &&
                      <video ref={hoverVideo} autoPlay loop muted playsInline>
                        <source src={thisNode.hover} type="video/mp4"/>
                        Your browser does not support the video tag.
                      </video>
                    }
                  </div>
                }
              </div>
            )}

            {thisNode.url && (
              <div style={{height: '100%'}}>
                <div className="hover-default" style={{background: 'url('+thisNode.image+') no-repeat', backgroundSize: 'contain'}}>
                  <div style={{cursor: 'pointer', height: '100%'}}>
                    <a href={formatUrl(thisNode.url)} target={thisNode.target} rel="noopener noreferrer" style={{display: 'block', height: '100%'}}>&nbsp;</a>
                  </div>
                </div>

                {thisNode.hover &&
                  <div className="hover-hover">
                    <a href={formatUrl(thisNode.url)} target={thisNode.target} rel="noopener noreferrer" style={{display: 'block', height: '100%'}}>
                      {thisNode.hover_type === 'image' &&
                        <img src={thisNode.hover} alt="" />
                      }
                      {thisNode.hover_type === 'video' &&
                        <video ref={hoverVideo} autoPlay loop muted playsInline>
                          <source src={thisNode.hover} type="video/mp4"/>
                          Your browser does not support the video tag.
                        </video>
                      }
                    </a>
                  </div>
                }
              </div>
            )}

            {imageHasDownloads(thisNode) && (
              <div className={attachmentClass} style={{left:x, top:y, transform: 'scale('+calculateViewport(viewport)+')'}} onClick={(e) => { e.preventDefault(); showModal(thisNode) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Text node */}
      {node.text && (
        <span dangerouslySetInnerHTML={{__html: calculateFontSize(node.text, viewport)}}></span>
      )}

      {node.children && node.children.length > 0 && (
        node.children.map((child, index) => (
          <TreeNode key={index} node={child} items={items} viewport={viewport} props={props} />
        ))
      )}
    </div>
  );
};

class Figma extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      height: 0,
      viewport: 1
    }

    this.containerRef = React.createRef();
    this.handleViewportChange = this.handleViewportChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      items: this.props.data.items,
    }, () => {
      // Call handleViewportChange after setting initial data
      this.handleViewportChange();
      window.addEventListener('resize', this.handleViewportChange);
    });
  }

  componentWillUnmount() {
    // Clean up event listener
    window.removeEventListener('resize', this.handleViewportChange);
  }

  // Calculate viewport size and update state
  handleViewportChange() {
    // Use the ref to get the current width of the container element
    const containerWidth = this.containerRef.current
      ? this.containerRef.current.getBoundingClientRect().width
      : 250;

    let newViewport = containerWidth / this.state.data.content_width;
    let viewport = newViewport > 1 ? 1 : newViewport;
    let height = this.state.data.content_height * viewport;

    // Update state dynamically
    this.setState({
      height: height,
      viewport: viewport,
    });
  }

  render () {
    return (
      <div
        ref={this.containerRef}
        style={{
          position: 'relative',
          width: this.state.data.content_width+'px',
          left: this.state.data.left+'px',
          marginTop: this.state.data.top,
          maxWidth: '100%'
        }}>
        <div>
          {/* Generate content */}
          {this.state.data.data_desktop && (
            <div className="module_figma">
              <div className="content-area" style={{'height': this.state.height+'px', 'transform':'scale('+this.state.viewport+')'}}>
                {this.state.data.data_desktop.map((node, index) => (
                  <TreeNode key={index} node={node} items={this.state.data.items} viewport={this.state.viewport} props={this.props} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Figma;
