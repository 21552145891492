import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import './Colors.scss';
import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';
import ColorShareIcon from '../../partials/ColorShareIcon';

import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

class Colors extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      brand: false,
      copied: false
    }
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
      data: this.props.data,
      items: this.props.data.items
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      brand: newProps.brand,
    });
  }

  colorTitle = (title) => {
    let c = title.split(' ');
    if(!c[1]) return false;
    return c[1] + (c[2] ? ' ' + c[2] : '');
  }

  onCopy = () => {
    document.querySelector('.fixed-click-to-copy').style.display = 'none';
    this.setState({copied: true});
  };

  resetCopy = () => {
    this.setState({copied: false});

     document.querySelector('.fixed-click-to-copy').style.display = 'none';
  };

  forceColorBubbleCopyHover = (e) => {
    var currentBubbleRect = e.currentTarget.getBoundingClientRect();

    document.querySelector('.fixed-click-to-copy').style.top = currentBubbleRect.top-40+'px';
    document.querySelector('.fixed-click-to-copy').style.left = currentBubbleRect.left+'px';
    document.querySelector('.fixed-click-to-copy').style.display = 'block';
    document.querySelectorAll("span.click-to-copy").forEach(node => {
      node.classList.remove('hide-copy-bubble');
    });
  }

  colorContrast = (hex) => {
    let hexcolor = hex.replace("#", "");
    let r = parseInt(hexcolor.substr(0,2),16);
    let g = parseInt(hexcolor.substr(2,2),16);
    let b = parseInt(hexcolor.substr(4,2),16);
    let yiq = ((r*299)+(g*587)+(b*114))/1000;

    return (yiq >= 220) ? 'black' : 'white';
  }

  render () {
    var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
    var moduleRowClass = this.state.data.border_radius > 34 && this.state.data.border_radius < 81 ? 'centered' : this.state.data.border_radius > 80 ? 'rounded' : 'default';
    var moduleLegacyClass = this.state.data.legacy_version === '1' ? 'legacy-module-v-1' : 'legacy-module-v-2';

    return (
      <div>
        <div
          className={'module'}
          style={{
            paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
            paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
            backgroundColor:this.state.data.background === 'transparent' ? this.props.brand.main_background_color : this.state.data.background,
            backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
            backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
          }}
        >
          <VideoBackground src={this.state.data.bg_video} />
          {this.state.data.dim === 1 && (
            <div className="module-bg-dim"></div>
          )}
          <ShareLocation module={this.state.data.id} />
            <div className={'module-colors row color-module-'+this.state.data.id+' '+moduleRowClass+' '+moduleLegacyClass}>
              {this.state.data.text && (
                <div className="col-12">
                  <div className="dynamic-content" style={{'width':this.state.data.text_width+'px', 'left':this.state.data.text_left+'px', 'marginTop':this.state.data.text_top+'px'}}>
                    <div className="editor-content" dangerouslySetInnerHTML={{__html: this.state.data.text}}></div>
                  </div>
                </div>
              )}

                <div className="fixed-click-to-copy">
                  <ColorShareIcon></ColorShareIcon>
                </div>

                {this.state.items.map(item =>
                <div key={item.id} className={`colors-items-wrapp col-xs-12 col-lg-${this.state.data.layout} ${item.title && item.title !== 'Title...' ? '' : 'hide-color-item-title-in-preview '}`} style={{paddingLeft:this.state.data.column_padding, paddingRight:this.state.data.column_padding, paddingTop:this.state.data.column_padding, paddingBottom:this.state.data.column_padding}}>
                  <div className="module-colors__item">
                    <div className={`title dynamic-content ${isHTML(item.title) ? "" : "without-any-tags"}`} style={{color: this.state.data.title_color === '#09163d' || this.state.data.title_color === '#16171a' ? this.state.brand.text_color : this.state.data.title_color}} dangerouslySetInnerHTML={{__html: item.title ? item.title : '&nbsp;'}}></div>
                    <div className={`color-box`} style={{border:this.state.data.border_width, borderColor:this.state.data.border_color, borderStyle:`solid` ,borderRadius:this.state.data.border_radius, height:item.height+'px'}}>
                      <div className={'color'} style={{backgroundColor: this.state.data.border_radius > 80 ? 'transparent' : item.hex , borderRadius: !this.state.data.border_width || this.state.data.border_width === '0' ? (this.state.data.border_radius+2)+'px' : '0'}}>
                        <div style={{'backgroundColor': !item.background_color ? this.state.data.code_background : item.background_color}} className="custom-color-overlay only-on-hover-overlay"></div>
                      </div>

                      <div className="data" style={{backgroundColor: !item.background_color ? this.state.data.code_background : item.background_color}}>
                        <div>
                        {item.show_custom_input === 1 && item.custom_input && (
                          <div className="custom_input" style={{color: item.code_color ? item.code_color : this.state.data.code_color }}>
                            <CopyToClipboard onCopy={this.onCopy} text={item.custom_input}>
                                <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.custom_input}</span>
                              </CopyToClipboard>
                          </div>
                        )}
                        {item.show_pantone === 1 && item.pantone && (
                          <div className="pantone" style={{color: item.code_color ? item.code_color : this.state.data.code_color }}>
                            <span>Pantone:</span>
                            {item.pantone && (
                              <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                                <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[0]}</span>
                              </CopyToClipboard>
                            )}

                            {item.pantone && (
                              <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                                <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[1]}</span>
                              </CopyToClipboard>
                            )}

                            {item.pantone && (
                              <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                                <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[2]}</span>
                              </CopyToClipboard>
                            )}

                            {item.pantone && (
                              <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                                <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[3]}</span>
                              </CopyToClipboard>
                            )}

                            {item.pantone && (
                              <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                                <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[4]}</span>
                              </CopyToClipboard>
                            )}
                          </div>
                        )}

                        {item.show_cmyk === 1 && (
                        <div style={{color: item.code_color ? item.code_color : this.state.data.code_color }}>
                          <span>CMYK:</span>
                          <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[0]}</span>
                          </CopyToClipboard>

                          <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[1]}</span>
                          </CopyToClipboard>

                          <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[2]}</span>
                          </CopyToClipboard>

                          <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[3]}</span>
                          </CopyToClipboard>
                        </div>
                        )}

                        {item.show_rgb === 1 && (
                        <div style={{color: item.code_color ? item.code_color : this.state.data.code_color }}>
                          <span>RGB:</span>
                          <CopyToClipboard onCopy={this.onCopy} text={item.rgb}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.rgb.split(' ')[0]}</span>
                          </CopyToClipboard>

                          <CopyToClipboard onCopy={this.onCopy} text={item.rgb}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.rgb.split(' ')[1]}</span>
                          </CopyToClipboard>

                          <CopyToClipboard onCopy={this.onCopy} text={item.rgb}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.rgb.split(' ')[2]}</span>
                          </CopyToClipboard>
                        </div>
                        )}

                        <div style={{color: item.code_color ? item.code_color : this.state.data.code_color }}>
                          <span>HEX:</span>
                          <CopyToClipboard onCopy={this.onCopy} text={item.hex}>
                            <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.hex}</span>
                          </CopyToClipboard>
                        </div>
                      </div>
                      </div>
                      <div className="color-mask" style={{backgroundColor:item.hex}}>
                          <span style={{color:!item.code_static_color ? this.state.data.code_static_color : item.code_static_color}}>{item.hex}</span>
                      </div>
                    </div>
                  </div>
                </div>
                )}
            </div>
        </div>
      </div>
    );
  }
}

export default Colors;
