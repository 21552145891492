import React, { Component } from 'react';
import axios from "axios";
import './Auth.scss';

const editors = ["admin", "owner", "editor"];

class Auth extends Component {

  constructor() {
    super();
    this.state = {
      brand           : false,
      page            : false,
      loggedIn        : false,
      showLogin       : false,
      userType        : false,
      userImage       : 'https://assets.'+window.MAIN_DOMAIN+'/users/avatar.png',
      showDropdown    : false,
      showLoginModal  : false,
      email           : '',
      password        : '',
      token           : false,
      isSending       : false,
      emailValid      : false,
      showError       : false,
      errorText       : 'Enter valid email and password!',
      validToken      : false,
      emailFocus      : false,
      passwordFocus   : false,
      screen          : 'email',
      damOpen         : false,
      code            : ["", "", "", "", ""], // For 5-digit 2FA
      isCodeSubmitted : false,
    }

    // Create an array of 5 refs, one for each digit input
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef()
    ];

    // Refs to the code submit button
    this.submitButtonRef = React.createRef();

    this.wrapperRef         = React.createRef();
    this.loginFormRef       = React.createRef();
    this.logOut             = this.logOut.bind(this);
    this.toggleLogin        = this.toggleLogin.bind(this);
    this.toggleDropdown     = this.toggleDropdown.bind(this);
    this.closeDropdown      = this.closeDropdown.bind(this);
    this.handleScroll       = this.handleScroll.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleChange       = this.handleChange.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);
    this.toggleAssets       = this.toggleAssets.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown     = this.handleKeyDown.bind(this);
    this.handlePaste       = this.handlePaste.bind(this);
    this.handleSubmitCode  = this.handleSubmitCode.bind(this);
  }

  // Assets manager
  toggleAssets = () => {
    this.setState({
      damOpen: this.state.damOpen ? false : true
    });

    this.props.openDam();
  }

  toggleLogin = () => {
    this.setState({
      showLoginModal: this.state.showLoginModal ? false : true
    });
  }

  toggleDropdown = () => {
    this.setState({
      showDropdown: true
    });
  }

  closeDropdown = () => {
    this.setState({
      showDropdown: false
    });
  }

  logOut = () => {
    axios.post(window.API + 'logout', {},{
      headers: {
        Pragma: 'no-cache',
        Authorization: localStorage.getItem('authToken') || ''
      }
    }).then(function (response) {}).catch(error => {});

    localStorage.removeItem('authToken');
    localStorage.removeItem('authUser');

    window.location.reload();
  }

  componentDidUpdate(prevProps) {
    // Check if damOpen prop has changed
    if (this.props.damOpen !== prevProps.damOpen) {
      // Perform actions based on the new value of damOpen
      if (!this.props.damOpen) {
        // DAM is closed, perform any necessary actions
        this.setState({
          damOpen: false
        });
      } else {
        this.setState({
          damOpen: true
        });
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand     : newProps.brand,
        page      : newProps.page,
        loggedIn  : localStorage.getItem('authToken') ? true : false,
        token     : localStorage.getItem('authToken'),
        showLogin : newProps.showLogin,
      });

      if(localStorage.getItem('authUser')) {
        let user = JSON.parse(localStorage.getItem('authUser'));
        this.setState({
          userType: user.type,
          userImage: user.image,
    	  });
      }
    }
  }

  // Close dropdown on scroll
  handleScroll = (event) => {
    if(this.state.showDropdown) {
      this.setState({
        showDropdown: false
      });
    }
  }

  // Close dropdown oro login form on click outside
  handleClickOutside(event) {
    if (this.state.showDropdown && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showDropdown: false
      });
    }

    if (this.state.showLoginModal && this.loginFormRef && !this.loginFormRef.current.contains(event.target) && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showLoginModal: false
      });
    }
  }

  handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});

		this.validateField(name, event.target.value);
	};

  validateField(fieldName, value) {
		let emailValid = this.state.emailValid;
		let passwordValid = this.state.passwordValid;

		switch (fieldName) {
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
			case 'password':
				passwordValid = (value.length >= 2) ? true : false;
				break;
			default:
				break;
		}

		this.setState({
			emailValid: emailValid,
			passwordValid: passwordValid
		});
	}

  handleSubmit = evt => {
		evt.preventDefault();

    if (this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      isSending: true,
      errorText: '&nbsp;'
    });

		let self = this;

    if(!this.state.emailValid) {
      this.setState({
        showError: true,
        isSending: false,
        errorText: 'Enter valid email and password!'
      });

      return false;
    }

		axios.post(window.API + 'authorize/email', {
			email: this.state.email,
      brand: this.state.brand.link,
      page: this.state.page,
      redirect: window.location.href.split('?')[0]
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      // Status OK, save token in localstorage, reload page
      if(response.data.status) {
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          self.setState({
            screen: 'password',
            isSending: false
          });
        }
      } else {
        self.setState({
          showError: true,
          isSending: false,
          errorText: response.data.user ? 'You don\'t have access to this brand' : 'Incorrect email!'
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        isSending: false,
        errorText: error
      });
		})
	};

  handleSubmitPassword = evt => {
		evt.preventDefault();

    if (this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isSending: true
    });

		let self = this;

    if(!this.state.passwordValid) {
      this.setState({
        showError: true,
        errorText: 'Enter password!',
        isSending: false
      });

      return false;
    }

		axios.post(window.API + 'authorize/password', {
			email: this.state.email,
			password: this.state.password,
      brand: this.state.brand.link,
      page: this.state.page
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      // Status OK, save token in localstorage, reload page
      if (response.data.status) {
         // 2FA
         if (response.data.mfa) {
          self.setState({
            isSending: false,
            screen: 'code'
          });
        } else {
          localStorage.setItem('authToken', response.data.token);
          localStorage.setItem('authUser', JSON.stringify(response.data.user));
          window.location.reload();
        }
      } else {
        self.setState({
          showError: true,
          errorText: 'Incorrect password!',
          isSending: false
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isSending: false
      });
		})
	};

  onEmailFocus = () => {
    this.setState({ emailFocus: true })
  }

  onEmailBlur = () => {
    this.setState({ emailFocus: false })
  }

  onPasswordFocus = () => {
    this.setState({ passwordFocus: true })
  }

  onPasswordBlur = () => {
    this.setState({ passwordFocus: false })
  }

  /**
   * Triggered on each input's `onChange`.
   * Grabs the last typed character if user pasted more than one.
   * Auto-focuses next input if a digit was typed.
   */
  handleInputChange(e, index) {
    const { value } = e.target;

    // Allow only digits
    if (!/^\d*$/.test(value)) return;

    // Last typed character if multiple were pasted
    const newValue = value ? value[value.length - 1] : "";

    const newCode = [...this.state.code];
    newCode[index] = newValue;

    // If a digit was entered and not the last input, move focus
    if (newValue && index < 4) {
      this.inputRefs[index + 1].current.focus();

      this.setState({ code: newCode });
    }
    // If it is the last input and a digit was entered, optionally focus a button:
    else if (index === 4 && newValue) {
      this.submitButtonRef.current.focus();

      // Submit when this.state.code is updated
      this.setState({ code: newCode }, () => {
        this.handleSubmitCode();
      });
    }
  }

  /**
   * Triggered on each input's `onPaste`.
   */
  handlePaste(e, index) {
    e.preventDefault();

    // Get the raw pasted text
    let pastedData = e.clipboardData.getData("text/plain") || "";

    // Keep only digits
    pastedData = pastedData.replace(/[^0-9]/g, "");

    if (!pastedData) return;

    // Clone 5-digit code array from state
    const newCode = [...this.state.code];

    // Spread the pasted digits into newCode starting at 'index'
    for (let i = index; i < 5; i++) {
      if (!pastedData) break;
      newCode[i] = pastedData[0];
      pastedData = pastedData.substring(1);
    }

    // Update state with the new code array
    this.setState({ code: newCode }, () => {
      const firstEmptyIndex = newCode.findIndex((val) => val === "");
      if (firstEmptyIndex !== -1) {
        this.inputRefs[firstEmptyIndex].current.focus();
      } else {
        this.submitButtonRef.current.focus();
        this.handleSubmitCode();
      }
    });
  }

  /**
   * Triggered on each input's `onKeyDown`.
   * If backspace is pressed on an empty field, move focus back.
   */
  handleKeyDown(e, index) {
    if (e.key === "Backspace" && !this.state.code[index]) {
      if (index > 0) {
        this.inputRefs[index - 1].current.focus();
      }
    }
  }

  /**
   * Submit handler just for the 2FA code.
   */
  handleSubmitCode() {
    if (this.state.isCodeSubmitted) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isCodeSubmitted: true
    });

    // Return to email
    if (!this.state.emailValid || !this.state.passwordValid) {
      this.setState({
        screen: 'email',
        isCodeSubmitted: false
      });
    }

    let self = this;

    // Submit the code
    axios.post(window.API + 'authorize/code', {
			email: this.state.email,
			password: this.state.password,
      code: this.state.code.join(""),
      brand: this.state.brand.link,
      studio: this.state.studio,
      page: this.state.page.page_id
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      localStorage.setItem('restoreToken', response.data.token);

      // Status OK, save token in localstorage, reload page
      if (response.data.status) {
        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('authUser', JSON.stringify(response.data.user));
        window.location.reload();
      } else {
        // If should update password
        if (response.data.reason === 'update') {
          localStorage.setItem('restoreToken', response.data.token);
          self.clickRef.current.click();

        // Incorrect code
        } else {
          self.setState({
            showError: true,
            errorText: 'Incorrect or expired code!',
            isCodeSubmitted: false
          });
        }
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isCodeSubmitted: false
      });
		})
  }

  // Switch back to password view
  toggleCode = () => {
		this.setState({
			screen: 'password'
		});
	};

  render () {
    return (
      <div>
        {this.state.showLogin &&
          <div>
            <div onMouseLeave={this.closeDropdown} ref={this.wrapperRef}>
              {this.state.loggedIn && this.state.userType && this.state.brand.dam === 1 &&
                <div className="assets">
                  <div className="assets__button" onClick={this.toggleAssets}>
                    {this.state.damOpen &&
                    <svg className="back-to-guidelines-svg" width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.9998 10L6.1748 8.825L2.35814 5L6.1748 1.175L4.9998 0L-0.000195026 5L4.9998 10Z" fill="#222222"></path>
                      </svg>
                    }
                    <span className="label">{this.state.damOpen ? 'Guidelines' : 'Assets library'}</span>
                  </div>
                </div>
              }

              <div className="auth">
                {this.state.loggedIn &&
                <div className="auth__avatar" onMouseEnter={this.toggleDropdown} onClick={this.toggleDropdown}>
                  <img src={this.state.userImage} alt="" />
                </div>
                }

                {!this.state.loggedIn &&
                <div className="auth__button" onClick={this.toggleLogin}>Login</div>
                }
              </div>
              <div className="dropdown-box">
              {this.state.showDropdown &&
              <ul className="dropdown">
                {this.state.userType && editors.includes(this.state.userType) &&
                <li><a href={process.env.REACT_APP_API_URL + '/auth?access_token=' + this.state.token + '&url=brand/' + this.state.brand.hash + '/page/' + this.state.page.page_id + '/' + this.state.page.url}>Edit mode</a></li>
                }

                {!this.state.brand.organization || this.state.brand.organization && this.state.brand.organization.dashboard &&
                <li><a href={process.env.REACT_APP_API_URL + '/auth?access_token=' + this.state.token + '&url=dashboard'}>Dashboard</a></li>
                }

                {!this.state.brand.organization || this.state.brand.organization && this.state.brand.organization.dashboard &&
                <li><a href={process.env.REACT_APP_API_URL + '/auth?access_token=' + this.state.token + '&url=dashboard/profile'}>My profile</a></li>
                }

                <li onClick={this.logOut}>Logout</li>
              </ul>
              }
              </div>
            </div>

            <div ref={this.loginFormRef} className={'login-modal' + (this.state.showLoginModal ? ' visible' : '')}>
              <div className="login-modal__form">

                  {this.state.screen === 'email' &&
                  <div>
                     <form onSubmit={this.handleSubmit} noValidate>
                      <div className="form-error">
                        <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                      </div>

                      <div className="form-group">
                        <label className={this.state.email || this.state.emailFocus ? 'active' : ''}>Enter email</label>
                        <input
                          type="email"
                          value={this.state.email}
                          onFocus={this.onEmailFocus}
                          onBlur={this.onEmailBlur}
                          onChange={this.handleChange('email')}
                          />
                      </div>

                      <div className="form-group">
                        <input type="submit" value={this.state.isSending ? 'Wait...' : 'Continue'} onClick={this.handleSubmit} />
                      </div>
                    </form>
                  </div>
                  }

                  {this.state.screen === 'password' &&
                  <div>
                     <form onSubmit={this.handleSubmit} noValidate>
                      <div className="form-error">
                        <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                      </div>

                      <div className="form-group">
                        <label className={this.state.password || this.state.passwordFocus ? 'active' : ''}>Enter password</label>
                        <input
                          type="password"
                          value={this.state.password}
                          onFocus={this.onPasswordFocus}
                          onBlur={this.onPasswordBlur}
                          onChange={this.handleChange('password')}
                          />
                      </div>

                      <div className="form-group">
                        <input type="submit" value={this.state.isSending ? 'Wait...' : 'Login'} onClick={this.handleSubmitPassword} />
                      </div>
                    </form>
                  </div>
                  }

                  {this.state.screen === 'code' &&
                    <div>
                      <p className="code">Your account is protected with two-factor authentication.<br/>We've sent you an email. Please enter the code below.</p>

                      <form onSubmit={this.handleSubmitCode} noValidate>
                        <div className="form-error">
                          <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                        </div>

                        <div className="form-group"
                          style={{
                            display: "flex",
                            gap: "8px",
                            justifyContent: "center"
                          }}
                        >
                          {this.state.code.map((digit, index) => (
                            <input
                              key={index}
                              type="text"
                              maxLength="1"
                              value={digit}
                              placeholder="•"
                              onChange={(e) => this.handleInputChange(e, index)}
                              onPaste={(e) => this.handlePaste(e, index)}
                              onKeyDown={(e) => this.handleKeyDown(e, index)}
                              ref={this.inputRefs[index]}
                              disabled={this.state.isCodeSubmitted}
                              style={{
                                width: "40px",
                                fontSize: "20px",
                                textAlign: "center",
                                marginRight: "4px"
                              }}
                            />
                          ))}
                        </div>

                        <div className="form-group">
                          <input ref={this.submitButtonRef} type="submit" value={this.state.isCodeSubmitted ? 'Wait...' : 'Submit'} onClick={this.handleSubmitCode} />
                        </div>
                      </form>

                      <div className="sso-link" onClick={this.toggleCode}>Resend the code</div>
                    </div>
                  }
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default Auth;
