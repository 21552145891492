import React, { Component } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ColorShareIcon from '../../../partials/ColorShareIcon';

import '../../colors/Colors.scss';

class Color extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      full_width: false,
      brand: false,
      copied: false
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      full_width: this.props.fullWidth,
      brand: this.props.brand,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      brand: newProps.brand,
      full_width: newProps.fullWidth,
      data: newProps.data
    });
  }

  colorTitle = (title) => {
    let c = title.split(' ');
    if(!c[1]) return false;
    return c[1] + (c[2] ? ' ' + c[2] : '');
  }

  onCopy = () => {
    document.querySelector('.fixed-click-to-copy').style.display = 'none';
    this.setState({copied: true});
  };

  resetCopy = () => {
    this.setState({copied: false});

     document.querySelector('.fixed-click-to-copy').style.display = 'none';
  };

  forceColorBubbleCopyHover = (e) => {
    var currentBubbleRect = e.currentTarget.getBoundingClientRect();

    document.querySelector('.fixed-click-to-copy').style.top = currentBubbleRect.top-40+'px';
    document.querySelector('.fixed-click-to-copy').style.left = currentBubbleRect.left+'px';
    document.querySelector('.fixed-click-to-copy').style.display = 'block';
    document.querySelectorAll("span.click-to-copy").forEach(node => {
      node.classList.remove('hide-copy-bubble');
    });
  }

  colorContrast = (hex) => {
    let hexcolor = hex.replace("#", "");
    let r = parseInt(hexcolor.substr(0,2),16);
    let g = parseInt(hexcolor.substr(2,2),16);
    let b = parseInt(hexcolor.substr(4,2),16);
    let yiq = ((r*299)+(g*587)+(b*114))/1000;

    return (yiq >= 220) ? 'black' : 'white';
  }

  render () {
    let item = this.state.data;
    var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
    var moduleRowClass = item.border_radius > 34 && item.border_radius < 81 ? 'centered' : item.border_radius > 80 ? 'rounded' : 'default';

    return (
      <div className={'module-colors '+moduleRowClass}>
        <div className="module-colors__item" style={{
            'width':(this.state.full_width ? '100%' : item.width+'px'),
            'marginLeft':(this.state.full_width ? '0' : item.left+'px'),
            'marginTop':item.top+'px'
          }}>
          {item.title && (
          <div className={`title dynamic-content ${isHTML(item.title) ? "" : "without-any-tags"}`} style={{ color: this.state.brand.text_color }} dangerouslySetInnerHTML={{ __html: item.title }}></div>
          )}
          <div className={`color-box`} style={{ border: item.border_width + 'px solid', borderColor: item.border_color, borderRadius: item.border_radius, height: item.height + 'px' }}>
            <div className={'color'} style={{ backgroundColor: item.border_radius > 80 ? 'transparent' : item.hex, borderRadius: !item.border_width || item.border_width === '0' ? (item.border_radius + 2) + 'px' : '0' }}>
              <div style={{'backgroundColor': !item.background_color ? item.code_background : item.background_color }} className="custom-color-overlay only-on-hover-overlay"></div>
            </div>

            <div className="data" style={{ backgroundColor: !item.background_color ? item.code_background : item.background_color }}>
              <div>
                {item.show_custom_input === 1 && item.custom_input && (
                  <div className="custom_input" style={{ color: item.code_color }}>
                    <CopyToClipboard onCopy={this.onCopy} text={item.custom_input}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.custom_input}</span>
                    </CopyToClipboard>
                  </div>
                )}
                {item.show_pantone === 1 && item.pantone && (
                  <div className="pantone" style={{ color: item.code_color }}>
                    <span>Pantone:</span>
                    {item.pantone && (
                      <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                        <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[0]}</span>
                      </CopyToClipboard>
                    )}

                    {item.pantone && (
                      <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                        <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[1]}</span>
                      </CopyToClipboard>
                    )}

                    {item.pantone && (
                      <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                        <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[2]}</span>
                      </CopyToClipboard>
                    )}

                    {item.pantone && (
                      <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                        <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[3]}</span>
                      </CopyToClipboard>
                    )}

                    {item.pantone && (
                      <CopyToClipboard onCopy={this.onCopy} text={item.pantone}>
                        <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.pantone.split(' ')[4]}</span>
                      </CopyToClipboard>
                    )}
                  </div>
                )}

                {item.show_cmyk === 1 && (
                  <div style={{ color: item.code_color }}>
                    <span>CMYK:</span>
                    <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[0]}</span>
                    </CopyToClipboard>

                    <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[1]}</span>
                    </CopyToClipboard>

                    <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[2]}</span>
                    </CopyToClipboard>

                    <CopyToClipboard onCopy={this.onCopy} text={item.cmyk}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.cmyk.split(' ')[3]}</span>
                    </CopyToClipboard>
                  </div>
                )}

                {item.show_rgb === 1 && (
                  <div style={{ color: item.code_color }}>
                    <span>RGB:</span>
                    <CopyToClipboard onCopy={this.onCopy} text={item.rgb}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.rgb.split(' ')[0]}</span>
                    </CopyToClipboard>

                    <CopyToClipboard onCopy={this.onCopy} text={item.rgb}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.rgb.split(' ')[1]}</span>
                    </CopyToClipboard>

                    <CopyToClipboard onCopy={this.onCopy} text={item.rgb}>
                      <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.rgb.split(' ')[2]}</span>
                    </CopyToClipboard>
                  </div>
                )}

                <div style={{ color: item.code_color }}>
                  <span>HEX:</span>
                  <CopyToClipboard onCopy={this.onCopy} text={item.hex}>
                    <span className={this.state.copied ? 'click-to-copy copied' : 'click-to-copy'} onMouseEnter={this.forceColorBubbleCopyHover} onMouseLeave={this.resetCopy}><ColorShareIcon></ColorShareIcon>{item.hex}</span>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className="color-mask" style={{ backgroundColor: item.hex }}>
              <span style={{ color: !item.code_static_color ? '#ffffff' : item.code_static_color }}>{item.hex}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Color;
