import React, { Component, useState, useRef } from 'react';
import axios from "axios";
import './Login.scss';

import DataRoomAnimation from 'assets/img/corebook-inner-circle.gif';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email      : '',
      password   : '',
      emailValid : false,
      studio      : '',
      page       : '',
      showError  : false,
      errorText  : 'Enter valid email and password!',
      token      : false,
      validToken : false,
      screen     : 'email',
      isSending  : false,
      dataRoom   : false,
      revertStyle : false,
      extra: false,
      code: ["", "", "", "", ""], // For 5-digit 2FA
      isCodeSubmitted: false,
    }

    this.clickRef = React.createRef();

    // Create an array of 5 refs, one for each digit input
    this.inputRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef()
    ];

    // Refs to the code submit button
    this.submitButtonRef = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleForm   = this.toggleForm.bind(this);
    this.toggleCode   = this.toggleCode.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown     = this.handleKeyDown.bind(this);
    this.handlePaste       = this.handlePaste.bind(this);
    this.handleSubmitCode  = this.handleSubmitCode.bind(this);
  }

  componentDidMount() {
    this.setState({
      brand: this.props.brand,
      studio: this.props.studio,
      page: this.props.page,
      menu: this.props.menu,
      token: localStorage.getItem('authToken'),
      validToken: this.props.brand.valid_token,
      dataRoom: this.props.brand.id === 9778,
      revertStyle: false,
      extra: this.props.extra,
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if(newProps.brand) {
      this.setState({
        brand: newProps.brand,
        studio: newProps.studio,
        page: newProps.page,
        menu: newProps.menu,
        token: localStorage.getItem('authToken'),
        validToken: newProps.brand.valid_token,
        dataRoom: newProps.brand.id === 9778,
        revertStyle: false,
        extra: newProps.extra,
  	  });
    }
  }

  toggleForm = () => {
		this.setState({
			screen: this.state.screen === 'email' ? 'password' : 'email',
		});
	};

  handleChange = name => event => {
		this.setState({
			[name]: event.target.value
		});

		this.validateField(name, event.target.value);
	};

  validateField(fieldName, value) {
		let emailValid = this.state.emailValid;
		let passwordValid = this.state.passwordValid;

		switch (fieldName) {
			case 'email':
				emailValid = value.match(/^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ? true : false;
				break;
			case 'password':
				passwordValid = (value.length >= 2) ? true : false;
				break;
			default:
				break;
		}

		this.setState({
			emailValid: emailValid,
			passwordValid: passwordValid
		});
	}

  // Submit email
  handleSubmit = evt => {
		evt.preventDefault();

    if (this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isSending: true,
      extra: false
    });

		let self = this;

    if (!this.state.emailValid) {
      this.setState({
        showError: true,
        errorText: 'Enter valid email!',
        isSending: false
      });

      return false;
    }

		axios.post(window.API + 'authorize/email', {
			email: this.state.email,
      brand: this.state.brand.link,
      studio: this.state.studio,
      page: this.state.page.page_id,
      redirect: window.location.href.split('?')[0]
		},{
      headers: {
        Pragma: 'no-cache'
      }
    }).then(function (response) {

      // Status OK, save token in localstorage, reload page or redirect to SSO
      if(response.data.status) {
        if (response.data.url) {
          window.location.href = response.data.url;
        } else {
          self.setState({
            screen: 'password',
            isSending: false
          });
        }
      } else {
        self.setState({
          showError: true,
          errorText: response.data.user ? 'You don\'t have access to this brand' : 'Incorrect email!',
          isSending: false
        });
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isSending: false
      });
		})
	};

  // Submit password
  handleSubmitPassword = evt => {
		evt.preventDefault();

    if (this.state.isSending) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isSending: true
    });

		let self = this;

    if (!this.state.passwordValid) {
      this.setState({
        showError: true,
        errorText: 'Enter password!',
        isSending: false
      });

      return false;
    }

		axios.post(window.API + 'authorize/password', {
			email: this.state.email,
			password: this.state.password,
      brand: this.state.brand.link,
      studio: this.state.studio,
      page: this.state.page.page_id
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      localStorage.setItem('restoreToken', response.data.token);

      // Status OK, save token in localstorage, reload page
      if (response.data.status) {
        // 2FA
        if (response.data.mfa) {
          self.setState({
            isSending: false,
            screen: 'code'
          });
        } else {
          localStorage.setItem('authToken', response.data.token);
          localStorage.setItem('authUser', JSON.stringify(response.data.user));
          window.location.reload();
        }
      } else {
        // If should update password
        if (response.data.reason === 'update') {
          localStorage.setItem('restoreToken', response.data.token);
          self.clickRef.current.click();

        // Incorrect password
        } else {
          self.setState({
            showError: true,
            errorText: 'Incorrect password!',
            isSending: false
          });
        }
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isSending: false
      });
		})
	};

  /**
   * Triggered on each input's `onChange`.
   * Grabs the last typed character if user pasted more than one.
   * Auto-focuses next input if a digit was typed.
   */
  handleInputChange(e, index) {
    const { value } = e.target;

    // Allow only digits
    if (!/^\d*$/.test(value)) return;

    // Last typed character if multiple were pasted
    const newValue = value ? value[value.length - 1] : "";

    const newCode = [...this.state.code];
    newCode[index] = newValue;

    // If a digit was entered and not the last input, move focus
    if (newValue && index < 4) {
      this.inputRefs[index + 1].current.focus();

      this.setState({ code: newCode });
    }
    // If it is the last input and a digit was entered, optionally focus a button:
    else if (index === 4 && newValue) {
      this.submitButtonRef.current.focus();

      // Submit when this.state.code is updated
      this.setState({ code: newCode }, () => {
        this.handleSubmitCode();
      });
    }
  }

  /**
   * Triggered on each input's `onPaste`.
   */
  handlePaste(e, index) {
    e.preventDefault();

    // Get the raw pasted text
    let pastedData = e.clipboardData.getData("text/plain") || "";

    // Keep only digits
    pastedData = pastedData.replace(/[^0-9]/g, "");

    if (!pastedData) return;

    // Clone 5-digit code array from state
    const newCode = [...this.state.code];

    // Spread the pasted digits into newCode starting at 'index'
    for (let i = index; i < 5; i++) {
      if (!pastedData) break;
      newCode[i] = pastedData[0];
      pastedData = pastedData.substring(1);
    }

    // Update state with the new code array
    this.setState({ code: newCode }, () => {
      const firstEmptyIndex = newCode.findIndex((val) => val === "");
      if (firstEmptyIndex !== -1) {
        this.inputRefs[firstEmptyIndex].current.focus();
      } else {
        this.submitButtonRef.current.focus();
        this.handleSubmitCode();
      }
    });
  }

  /**
   * Triggered on each input's `onKeyDown`.
   * If backspace is pressed on an empty field, move focus back.
   */
  handleKeyDown(e, index) {
    if (e.key === "Backspace" && !this.state.code[index]) {
      if (index > 0) {
        this.inputRefs[index - 1].current.focus();
      }
    }
  }

  /**
   * Submit handler just for the 2FA code.
   */
  handleSubmitCode() {
    if (this.state.isCodeSubmitted) {
      return false;
    }

    this.setState({
      showError: false,
      errorText: '',
      isCodeSubmitted: true
    });

    // Return to email
    if (!this.state.emailValid || !this.state.passwordValid) {
      this.setState({
        screen: 'email',
        isCodeSubmitted: false
      });
    }

    let self = this;

    // Submit the code
    axios.post(window.API + 'authorize/code', {
			email: this.state.email,
			password: this.state.password,
      code: this.state.code.join(""),
      brand: this.state.brand.link,
      studio: this.state.studio,
      page: this.state.page.page_id
		},{
      headers: {
        Pragma: 'no-cache',
      }
    }).then(function (response) {

      localStorage.setItem('restoreToken', response.data.token);

      // Status OK, save token in localstorage, reload page
      if (response.data.status) {
        localStorage.setItem('authToken', response.data.token);
        localStorage.setItem('authUser', JSON.stringify(response.data.user));
        window.location.reload();
      } else {
        // If should update password
        if (response.data.reason === 'update') {
          localStorage.setItem('restoreToken', response.data.token);
          self.clickRef.current.click();

        // Incorrect code
        } else {
          self.setState({
            showError: true,
            errorText: 'Incorrect or expired code!',
            isCodeSubmitted: false
          });
        }
      }
		}).catch(error => {
      self.setState({
        showError: true,
        errorText: error,
        isCodeSubmitted: false
      });
		})
  }

  // Switch back to password view
  toggleCode = () => {
		this.setState({
			screen: 'password'
		});
	};

  render () {
    return (
      <div>
        <div className={`login ${!this.state.token && this.state.brand && this.state.brand.id === 9778 ? "login--data-room" : ""}`}>
          <div className="login__form">
            {this.state.brand && this.state.brand.id === 3388 &&
            <h6>The brand is approachable, but this content needs to be locked</h6>
            }

            {this.state.brand && this.state.brand.id === 1780 &&
            <h6>This {this.state.brand.visibility === 'locked' ? 'project' : 'page'} is locked</h6>
            }

            {this.state.brand && this.state.brand.id === 9778 && this.state.page.page_id === 148784022366144 &&
            <h6
              ref={(el) => {
                if (el) {
                  el.style.setProperty('font-size', '52px', 'important');
                  el.style.setProperty('font-weight', '400', 'important');
                }
              }}
            >{this.state.brand.visibility === 'locked' ? 'Corebook° Data Room' : 'Corebook° Due Diligence'}</h6>
            }

            {this.state.brand && this.state.brand.id !== 3388 && this.state.brand && this.state.brand.id !== 9778 && this.state.brand && this.state.brand.id !== 1780 &&
            <h6>This {this.state.brand.visibility === 'locked' ? 'brand' : 'section'} is locked</h6>
            }

            {this.state.brand && this.state.brand.id === 9778 && this.state.page.page_id !== 148784022366144 &&
            <h6
              ref={(el) => {
                if (el) {
                  el.style.setProperty('font-size', '52px', 'important');
                  el.style.setProperty('font-weight', '400', 'important');
                }
              }}
            >Corebook° Data Room</h6>
            }

            {this.state.token && this.state.validToken && this.state.brand.id !== 9778 &&
            <p>Please <span className="underline" onClick={() => this.props.switchScreen('request',this.state.brand,this.state.studio,this.state.page)}>request access</span> to the Brand Owner.</p>
            }

            {this.state.token && this.state.validToken && this.state.brand.id === 9778 && this.state.page.page_id === 148784022366144 &&
            <p>Access to DD documents will be granted<br/>after the term sheet.</p>
            }

            {this.state.brand && !this.state.validToken && this.state.screen === 'email' &&
            <div>
              {this.state.brand.visibility !== 'locked' && this.state.brand.id !== 9778 &&
                <p>Please enter your viewer credentials<br/>or <span className="underline" onClick={() => this.props.switchScreen('request',this.state.brand,this.state.studio,this.state.page)}>request access</span> to the Brand Owner.</p>
              }
              {this.state.brand.visibility === 'locked' && this.state.brand.id !== 9778 &&
                <p>Please enter your viewer credentials<br/>or <span className="underline" onClick={() => this.props.switchScreen('request',this.state.brand,this.state.studio,this.state.page)}>request access</span> to the Brand Owner.</p>
              }
              {this.state.brand.id === 9778 &&
                <p>This is a private invitation for the Seed round.<br/>Enter your credentials to log in.</p>
              }

              <form onSubmit={this.handleSubmit} noValidate>
                <div className="form-error">
                  <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                  <div className={this.state.extra ? 'visible visible--extra' : ''}>{this.state.extra}</div>
                </div>

                <div className="form-group">
                  <input
                    type="email"
                    value={this.state.email}
                    placeholder="Enter email"
                    onChange={this.handleChange('email')}
                    />
                </div>

                <div className="form-group">
                  <input type="submit" value={this.state.isSending ? 'Wait...' : 'Continue'} onClick={this.handleSubmit} />
                </div>
              </form>
            </div>
            }

            {!this.state.validToken && this.state.screen === 'password' &&
            <div>
              <p>Enter password for<br/>{this.state.email}</p>

              <form onSubmit={this.handleSubmitPassword} noValidate>
                <div className="form-error">
                  <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    value={this.state.password}
                    placeholder="Enter password"
                    onChange={this.handleChange('password')}
                    autoComplete="new-password"
                    />
                </div>

                <div className="forgot-link">
                  <a href={window.EDITOR_URL + '/#!/forgot'}>Forgot password?</a>
                </div>

                <div className="form-group">
                  <input type="submit" value={this.state.isSending ? 'Wait...' : 'Login'} onClick={this.handleSubmitPassword} />
                </div>
              </form>

              <div className="sso-link" onClick={this.toggleForm}>Change user</div>
            </div>
            }

            {!this.state.validToken && this.state.screen === 'code' &&
            <div>
              <p className="code">Your account is protected with two-factor authentication.<br/>We've sent you an email. Please enter the code below.</p>

              <form onSubmit={this.handleSubmitCode} noValidate>
                <div className="form-error">
                  <div className={this.state.showError ? 'visible' : ''}>{this.state.errorText}</div>
                </div>

                <div className="form-group"
                  style={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "center"
                  }}
                >
                  {this.state.code.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={digit}
                      placeholder="•"
                      onChange={(e) => this.handleInputChange(e, index)}
                      onPaste={(e) => this.handlePaste(e, index)}
                      onKeyDown={(e) => this.handleKeyDown(e, index)}
                      ref={this.inputRefs[index]}
                      disabled={this.state.isCodeSubmitted}
                      style={{
                        width: "40px",
                        fontSize: "20px",
                        textAlign: "center",
                        marginRight: "4px"
                      }}
                    />
                  ))}
                </div>

                <div className="form-group">
                  <input ref={this.submitButtonRef} type="submit" value={this.state.isCodeSubmitted ? 'Wait...' : 'Submit'} onClick={this.handleSubmitCode} />
                </div>
              </form>

              <div className="sso-link" onClick={this.toggleCode}>Resend the code</div>
            </div>
            }
          </div>
        </div>

        <div ref={this.clickRef} onClick={() => this.props.switchScreen('update', this.state.brand, this.state.studio, this.state.page)}></div>

        {this.state.brand && !this.state.validToken && this.state.brand.id === 9778 &&
        <div className="data-room-side">
          <img src={DataRoomAnimation} alt="Corebook inner circle" />
        </div>
        }
      </div>
    );
  }
}

export default Login;
