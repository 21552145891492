import React, { Component } from 'react';
import axios from 'axios';
import './Dash.scss';

import ShareLocation from '../../partials/share/ShareLocation';
import VideoBackground from '../../partials/video/VideoBackground';

import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

class Dash extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      brand: false,
    }
  }

  componentDidMount() {
    this.setState({
      data: this.props.data,
      items: this.props.data.items
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      brand: newProps.brand,
    });
  }

  render () {
    return (
      <div>
        <div
          className={'module'}
          style={{
            paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
            paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
            backgroundColor:this.state.data.background === 'transparent' ? this.props.brand.main_background_color : this.state.data.background,
            backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
            backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
          }}
        >
          <VideoBackground src={this.state.data.bg_video} />
          {this.state.data.dim === 1 && (
            <div className="module-bg-dim"></div>
          )}
          <ShareLocation module={this.state.data.id} />
          <div className="module_downloads row">
            <div className="col-12">
              {this.state.items.map(item =>
                <div key={item.id} className="module_downloads__item">
                  <div className="module_downloads__item">
                    <a href={null} onClick={() => { this.props.openDashPortal(item) }}>
                      <h6>
                        {item.title}
                      </h6>
                      <div className="actions">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20"><defs><path id="wee3a" d="M1468 2620v4a2 2 0 0 1-2 2h-14a2 2 0 0 1-2-2v-4"/><path id="wee3b" d="M1454 2615l5 5 5-5"/><path id="wee3c" d="M1459 2620v-12"/></defs><g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3a"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3b"/></g><g transform="translate(-1449 -2607)"><use fill="#fff" fillOpacity="0" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#wee3c"/></g></g></svg>
                      </div>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dash;
