import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Slider from "react-slick";

import 'libs/react-photoswipe/lib/photoswipe.css';
import { PhotoSwipe } from 'libs/react-photoswipe/src';

import './Carousel.scss';

import ShareLocation from '../../partials/share/ShareLocation';
import { calculatePadding } from '../../../utils';

const windowWidth = window.innerWidth;

class Carousel extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      items: [],
      galleryIndex: 0,
      gallery: [],
      galleryOpen: false,
      resetHeight: false,
      zoomImages: false,
      activeSlide: 0,
      lastSlide: false,
      width: 0,
      height: 0
    }

    this.parentRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount() {
    let gallery = [];

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    for(let i = 0; i < this.props.data.items.length; i++) {
      let wichratio = false;
      
      if(this.props.data.crop == '1') {

        if(this.state.width <= 768){
          wichratio = this.props.data.items[i].image_1200;
        }
        else {
          if(this.state.data.layout === 3 || this.state.data.layout === 4) {
            wichratio = this.props.data.items[i].image_1200;
          } else if(this.state.data.layout === 2) {
            wichratio = this.props.data.items[i].image_1600;
          } else if(this.state.data.layout === 1) {
            wichratio = this.props.data.items[i].image_1920;
          } else {
            wichratio = this.props.data.items[i].image_1200;
          }
        }
      } else {
        wichratio = this.props.data.items[i].image_full;
      }

      let image = '';

      if(this.props.data.items[i].type !== 'video') {
        const img = new Image();
        img.src = wichratio;
        //img.onload = () => {

          if(this.props.data.crop == '1') {

            image = {
              src: wichratio,
              w: this.props.data.items[i].width,
              h: this.props.data.items[i].height,
              index: i
            }
          } else {
            image = {
              src: wichratio,
              w: this.props.data.items[i].image_full_width,
              h: this.props.data.items[i].image_full_height,
              index: i
            }
          }

          this.props.data.items[i].attachmentClass = 'btn-download-float';
          this.props.data.items[i].x = 0;
          this.props.data.items[i].y = 0;

          //gallery[i] = image;
          gallery.push(image);

        //};
      } else {
        image = {
          html: '<div class="videoSlide">' +
                '<div class="videoSlideInner">' +
                  '<video autoplay playsinline muted loop controls><source src="' +
                  this.props.data.items[i].filename + '" type="video/mp4" />' +
                  '</video>' +
                '</div>' +
              '</div>',
          index: i
        }

        this.props.data.items[i].attachmentClass = 'btn-download-float';
        this.props.data.items[i].x = 0;
        this.props.data.items[i].y = 0;

        //gallery[i] = image;
        gallery.push(image);
      }

    }

    this.setState({
      data: this.props.data,
      items: this.props.data.items,
      buttonsClass: 'btn-switch-slide',
      mouseButton: false,
      x: 0,
      y: 0,
      zoomImages: this.props.data.gallery_mode === 1,
      gallery: gallery
    });


    // Direct open modal
    const urlParams = new URLSearchParams(window.location.search);

    if(urlParams.get('p') && urlParams.get('m')*1 === this.props.data.id) {
      this.props.data.items.map((item, index) => {
        if(item.id === urlParams.get('p')*1) {
          let self = this;

          setTimeout(function(){
            self.showModal(item,index);
          },1000);

          return;
        }
      });
    }

    setTimeout(() => {
      this.setState({
        resetHeight: true,
      });
    },500);
  }

  imageHasDownloads = (item) => {
    return item.attachments && item.attachments.length > 0;
  }

  showModal = (item,index) => {
    if(!this.imageHasDownloads(item)) return false;

    let newItem = item;
    newItem.image = item.filename;

    this.props.openModal(item,this.state.gallery,index);
  }

  imageHeight = (item) => {
    let widths = [];
    widths['0'] = [];
    widths['1'] = [];
    widths['1']['3'] = 245;
    widths['1']['4'] = 336.66;
    widths['1']['6'] = 520;
    widths['0']['3'] = widths['1']['3']+20;
    widths['0']['4'] = widths['1']['4']+20;
    widths['0']['6'] = widths['1']['6']+20;

    return item.height/(item.width/widths[this.state.data.column_padding][this.state.data.layout])+'px';
  }

  openPhotoSwipe = (index) => {

    if(!this.state.zoomImages) return false;
    this.setState({
      galleryOpen: true,
      galleryIndex: index,
    });
  };

  handleClose = () => {
    this.setState({
      galleryOpen: false
    });
  };

  _onMouseUp(e) {

    if(this.state.mouseButton){
      let bounds = ReactDOM.findDOMNode(this).getBoundingClientRect();
      let half = bounds.left + bounds.width / 2;

      if (e.clientX > half) {
        this.slider.slickNext();
      } else {
        this.slider.slickPrev();
      }
    }
  }

  _onMouseEnter(e) {
    if(this.state.mouseButton){
      this.setState({
        buttonsClass: 'btn-switch-slide visible',
        pointerStyle: 'pointer',
        textIsSelected: false
      });
    }
  }

  _onMouseEnter2(e, image) {
    if(image.hover_type === 'video') {
      let video = document.querySelector('#video_item_'+image.id+' .hover-hover video')
      video.currentTime = 0;
    }
  }

  _onMouseMove(e) {
    if(this.state.mouseButton){
      let bounds = ReactDOM.findDOMNode(this).getBoundingClientRect();
      let half = bounds.left + bounds.width / 2;

      let visible = this.state.textIsSelected ? '' : ' visible';
      if (e.clientX > half) {
        if (this.state.buttonsClass.indexOf('btn-switch-slide--right') === -1) {
          this.setState({
            buttonsClass: 'btn-switch-slide'+visible+' btn-switch-slide--right btn-switch-slide--animate'
          });

          var _self = this;
          setTimeout(function(){
            _self.setState({
              buttonsClass: 'btn-switch-slide'+visible+' btn-switch-slide--right'
            });
          },200);
        }
      } else {
        if (this.state.buttonsClass.indexOf('btn-switch-slide--right') !== -1) {
          this.setState({
            buttonsClass: 'btn-switch-slide'+visible+' btn-switch-slide--animate'
          });

          var _self = this;
          setTimeout(function(){
            _self.setState({
              buttonsClass: 'btn-switch-slide'+visible
            });
          },200);
        }
      }

      this.setState({
        x: e.clientX > half ? (e.clientX + 5) : (e.clientX - 70),
        y: e.clientY - 30
      });
    }
  }
  _onMouseLeave(e) {
    if(this.state.mouseButton){
      this.setState({
        buttonsClass: 'btn-switch-slide',
        pointerStyle: 'initial'
      });
    }
  }

  onChange = (isVisible) => {
    if(!this.state.animationStoped){
      this.setState({
        contentStoped: !isVisible
      });
    }else{
      this.setState({
        contentStoped: true
      });
    }
  }

  previousSlide() {
    this.slider.slickPrev();
  }

  nextSlide() {
    this.slider.slickNext();
  }

  render () {

    const { galleryIndex, galleryOpen, gallery } = this.state;

    let slidestoshow = parseInt(this.state.data.layout);

    if(this.state.width <= 768) {
      slidestoshow = 1;
    }

    if(this.state.width > 768 && this.state.width <= 992 && slidestoshow !== 1) {
      slidestoshow = 2;
    }

    const settings = {
      infinite: false,
      speed: 300,
      slidesToShow: slidestoshow,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: false,
      dots: false,
      afterChange: current => {
        this.setState({ activeSlide: current })
        if(current >= this.state.items.length-parseInt(slidestoshow)) {
          this.setState({ lastSlide: 'last-slide' })
        } else {
          this.setState({ lastSlide: '' })
        }
      }
    }

    return (
        <div id="parent" ref={this.parentRef}>
          <div
            className={'module'}
            style={{
              paddingTop: calculatePadding(windowWidth, this.state.data.row_padding_top)+'px',
              paddingBottom: calculatePadding(windowWidth, this.state.data.row_padding_bottom)+'px',
              backgroundColor:this.state.data.background === 'transparent' ? this.props.brand.main_background_color : this.state.data.background,
              backgroundImage:this.state.data.bg_image ? 'url('+this.state.data.bg_image+')' : 'none',
              backgroundPosition:this.state.data.bg_image_x+'% '+this.state.data.bg_image_y+'%'
            }}
          >
            <ShareLocation module={this.state.data.id} />
            <div className="carousel-div"
            onMouseUp={this._onMouseUp.bind(this)}
            onMouseMove={this._onMouseMove.bind(this)}
            onMouseEnter={this._onMouseEnter.bind(this)}
            onMouseLeave={this._onMouseLeave.bind(this)}
            >
              <div className={'carousel-slider'+(this.state.data.full_width === 1 ? ' full--width' : '')+' '+(this.state.width <= 992 ? ' mobile-slider' : '')+' layout-padding-'+this.state.data.column_padding+' slide_current_'+this.state.activeSlide+' '+this.state.lastSlide }>
                <Slider ref={c => (this.slider = c)} {...settings}>
                  {this.state.items.map((image, index) =>
                    <div id={'video_item_' +image.id} className={image.hover ? 'with--hover' : ''} onMouseEnter={(e) => { this._onMouseEnter2(e,image) }} key={index}>
                      <div className="image-block" style={{borderRadius: this.state.data.border_radius, marginRight: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px': '', marginLeft: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px': ''}}>

                        <div key={image.id} onClick={(e) => { e.preventDefault(); this.imageHasDownloads(image) ? this.showModal(image,index) : this.openPhotoSwipe(index) }}>
                          <div className="hover-default">
                            {image.type === 'image' &&
                              <img style={{cursor: this.imageHasDownloads(image) ? 'pointer' : (this.state.zoomImages ? 'zoom-in' : 'default')}} className={'carousel-div-item carousel-div-item-padding-'+this.state.data.column_padding+' carousel-div-item-layout-'+this.state.data.layout+''} src={
                                (() => {
                                  if(this.state.data.crop == '1') {
                                    if(this.state.width <= 768){
                                      return image.image_1200;
                                    }
                                    else {
                                      if(this.state.data.layout === 3 || this.state.data.layout === 4) {
                                        return image.image_1200;
                                      } else if(this.state.data.layout === 2) {
                                        return image.image_1600;
                                      } else if(this.state.data.layout === 1) {
                                        return image.image_1920;
                                      } else {
                                        return image.image_1200;
                                      }
                                    }
                                  } else {
                                    return image.image_full;
                                  }
                                })()

                              } alt=""/>
                            }
                            {image.type === 'video' &&
                              <div className={'background-video-block background-video-block-no-crop-'+this.state.data.crop+' background-video-block-ratio-'+this.state.data.ratio+''}>
                                <video style={{cursor: this.imageHasDownloads(image) ? 'pointer' : (this.state.zoomImages ? 'zoom-in' : 'default')}} className={'carousel-div-item carousel-div-item-padding-'+this.state.data.column_padding+' carousel-div-item-layout-'+this.state.data.layout+''} autoPlay loop muted playsInline>
                                    <source src={image.filename} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                  </video>
                                </div>
                            }
                          </div>
                          {image.hover &&
                            <div className="hover-hover">
                              {image.hover_type === 'image' &&
                                <img src={
                                (() => {
                                  if(this.state.width <= 768){
                                    return image.hover_1200;
                                  }
                                  else {
                                    if(this.state.data.layout === 3 || this.state.data.layout === 4) {
                                      return image.hover_1200;
                                    } else if(this.state.data.layout === 2) {
                                      return image.hover_1600;
                                    } else if(this.state.data.layout === 1) {
                                      return image.hover_1920;
                                    } else {
                                      return image.hover_1200;
                                    }
                                  }
                                })()

                              } style={{height: this.state.resetHeight ? 'auto' : this.imageHeight(image)}} alt=""/>
                              }

                              {image.hover_type === 'video' &&
                                <video style={{height: this.state.resetHeight ? 'auto' : this.imageHeight(image)}} autoPlay loop muted playsInline>
                                  <source src={image.hover} type="video/mp4"/>
                                  Your browser does not support the video tag.
                                </video>
                              }
                            </div>
                          }
                        </div>
                      </div>
                      <div className="title-wrap" style={{marginLeft: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px' : '', marginRight: this.state.data.column_padding === 1 ? this.state.data.column_padding_value+'px' : ''}}>
                        <div
                          className="title-quill editor-content"

                              style={{display: !image.description ? 'none' : 'block',color: this.state.data.title_color === '#09163d' || this.state.data.title_color === '#16171a' ? this.state.brand.text_color : this.state.data.title_color}} dangerouslySetInnerHTML={{__html: image.description ? image.description : '&nbsp;'}}>
                        </div>
                      </div>
                    </div>
                  )}
                </Slider>
                <div onClick={this.previousSlide.bind(this)} className="carousel-div-left-btn btn-switch-slide">
                  <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125">
                    <path fill="#fff" d="M59.6,34.9c-0.6,0.6-0.6,1.5,0,2.1L71,48.5H31.1c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H71L59.6,62.9
                    c-0.6,0.6-0.6,1.5,0,2.1s1.5,0.6,2.1,0l14-14c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1l0,0c0.5-0.5,0.5-1.3,0-1.8l0,0c0,0-0.1,0-0.1-0.1
                    c0,0,0,0-0.1-0.1l-14-14C61.1,34.3,60.1,34.3,59.6,34.9z"/>
                  </svg>
                </div>
                <div onClick={this.nextSlide.bind(this)} className="carousel-div-right-btn btn-switch-slide btn-switch-slide--right ">
                  <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125">
                    <path fill="#fff" d="M59.6,34.9c-0.6,0.6-0.6,1.5,0,2.1L71,48.5H31.1c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H71L59.6,62.9
                    c-0.6,0.6-0.6,1.5,0,2.1s1.5,0.6,2.1,0l14-14c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1l0,0c0.5-0.5,0.5-1.3,0-1.8l0,0c0,0-0.1,0-0.1-0.1
                    c0,0,0,0-0.1-0.1l-14-14C61.1,34.3,60.1,34.3,59.6,34.9z"/>
                  </svg>
                </div>

                <div className={this.state.buttonsClass} style={{left: this.state.x, top:this.state.y }}>
                  <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 125">
                    <path fill="#fff" d="M59.6,34.9c-0.6,0.6-0.6,1.5,0,2.1L71,48.5H31.1c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H71L59.6,62.9
                    c-0.6,0.6-0.6,1.5,0,2.1s1.5,0.6,2.1,0l14-14c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1l0,0c0.5-0.5,0.5-1.3,0-1.8l0,0c0,0-0.1,0-0.1-0.1
                    c0,0,0,0-0.1-0.1l-14-14C61.1,34.3,60.1,34.3,59.6,34.9z"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>

         <PhotoSwipe
          isOpen={galleryOpen}
          items={gallery}
          options={{
            index: galleryIndex,
            captionEl: false,
            shareEl: false,
            closeOnScroll: true,
            zoomEl: false,
            history: false,
            mainClass: 'CarouselModule',
            barsSize: {top:0, bottom:'auto'}
          }}
          onClose={this.handleClose}
        />

      </div>
    );
  }
}

export default Carousel;
